<template>
  <Layout>
    <div>
      <div class="row">
        <div class="col-md-12 d-flex justify-end">
          <button class="btn btn-info" data-toggle="modal" data-target=".bd-example-modal-xl">
            View Transactions
          </button>
        </div>

        <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="text-center" id="exampleModalLabel"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span class="text-danger">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5>Transactions Category</h5>
                <div class="form-group">
                  <select v-model="transaction" class="form-control" name="" id="">
                    <option v-for="trans in transaction_type" :key="trans.id" :value="trans.id">{{ trans.fullName }}
                    </option>
                  </select>
                </div>

                <div class="container my-5">
                  <div class=" row ">
                    <div class="col-md-4">
                      <label for="startDate">Transaction Start Date</label>
                      <input id="startDate" v-model="transtartDate" name="startDate" type="date" class="form-control" />
                    </div>
                    <div class="col-md-4">
                      <label for="endDate">Transaction End Date</label>
                      <input id="endDate" name="endDate" v-model="transendDate" type="date" class="form-control" />
                    </div>
                    <div class="col-md-4">
                      <label class="opacity-0" for="endDate">End Date</label>
                      <button @click.prevent="GetTransactions" class="btn btn-primary btn-block">
                        Filter
                      </button>
                    </div>
                  </div>

                  <div v-if="isloading" class="row  justify-content-center">
                    <fade-loader :loading="isloading" :color="color"></fade-loader>
                  </div>

                  <table class="table my-5">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Customer fullName</th>
                        <th scope="col">Customer Phone Number</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Transaction Date</th>
                        <th scope="col">Summary</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="trans in transactions" :key="trans.id">
                        <td>
                          {{
                          trans.clientFirstName + " " + trans.clientLastName
                          }}
                        </td>
                        <td>{{ trans.clientPhoneNumber }}</td>
                        <td>{{ trans.amount }}</td>
                        <td>{{ trans.createdDate | formatDate("LLLL") }}</td>
                        <td>{{ trans.packageSummary }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td class="table-secondary" colspan="4">
                          Total Amount:{{ countryCurrency}} {{ totalTransactions }} for
                          {{ transactions.length }} Member(s)
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="row  justify-content-center">
        <fade-loader :loading="loading" :color="color"></fade-loader>
      </div>
      <div v-else>
        <div v-if="businessType == 3" class="row d-flex justify-content-center">
          <div class="col-md-4 ">
            <label>FoodHub Total Revenue</label>
            <a class="datcard my-3 space-y-6 " href="#">
              <div class="mt-4">
                <span style="color:white; font-weight:bold;" class="h5">
                  Total Purchased Quantity :
                  {{
                  foodHubTotalPurchasedQuantity
                  ? foodHubTotalPurchasedQuantity
                  : 0
                  }}
                </span>
              </div>
              <div>
                <!-- <h5 style="color:white;" class="p">Paid</h5> -->
                <span style="color:white; font-weight:bold;" class="h5">
                  Total Revenue : {{ countryCurrency }}
                  {{ foodHubTotalRevenue ? foodHubTotalRevenue.toFixed(2) : 0 }}</span>
              </div>
              <div>
                <!-- <h5 style="color:white;" class="p">
                  Balance
                </h5> -->
                <h3 style="color:white; font-weight:bold;" class="h5">
                  Total Revenue Count :
                  {{ foodHubTotalRevenueCount ? foodHubTotalRevenueCount : 0 }}
                </h3>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>

          <div class="col-md-4">
            <label>Food Hub Total Revenue By Date</label>
            <a class="datcard my-3 space-y-6" href="#">
              <div class="mt-4">
                <span style="color:white; font-weight:bold;" class="h4">
                  Total Purchased Quantity:
                  {{
                  revenue.item1
                  ? revenue.item1.foodHubTotalPurchasedQuantity
                  : 0
                  }}
                </span>
              </div>
              <div>
                <span style="color:white; font-weight:bold;" class="h4">
                  Total Revenue : {{ countryCurrency }}
                  {{ revenue.item1 ? revenue.item1.foodHubTotalRevenue.toFixed(2) : 0 }}
                </span>
              </div>
              <div>
                <span style="color:white; font-weight:bold;" class="h4">
                  Total Revenue Count:
                  {{
                  revenue.item1 ? revenue.item1.foodHubTotalRevenueCount : 0
                  }}</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>

          <div class="container my-5">
            <div class="row d-flex justify-content-center">
              <div class="col-md-3">
                <label for="startDate">Revenue Start Date</label>
                <input id="startDate" v-model="startDate" name="startDate" type="date" class="form-control" />
              </div>
              <div class="col-md-3">
                <label for="endDate">Revenue End Date</label>
                <input id="endDate" name="endDate" v-model="endDate" type="date" class="form-control" />
              </div>
              <div class="col-md-2">
                <label class="opacity-0" for="endDate">End Date</label>
                <button @click.prevent="getRevenue" class="btn btn-primary btn-block">
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="
            businessType != 3 && (revenue || revenue.item1 || revenue.item2)
          " class="row">
          <div class="col-md-4 ">
            <label>Invoice Total Revenue</label>
            <a class="datcard my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item1 ? revenue.item2.invoiceTotalRevenue.toFixed(2) : 0
                  }}</span>
              </div>
              <div>
                <h4 style="color:white;" class="p">Paid</h4>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{ revenue.item1 ? revenue.item2.invoiceTotalPaid.toFixed(2) : 0 }}</span>
              </div>
              <div>
                <h4 style="color:white;" class="p">
                  Balance
                </h4>
                <h3 style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item2
                  ? (revenue.item2.invoiceTotalRevenue -
                  revenue.item2.invoiceTotalPaid).toFixed(2)
                  : 0
                  }}
                </h3>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>
          <div v-if="revenue.item2" class="col-md-4">
            <label>Health Hub Total Revenue</label>
            <a class="datcard2 my-3 space-y-2" href="#">
              <div>
                <h3 style="color:white; font-weight:bold;">
                  {{ countryCurrency}}
                  {{ revenue.item2.healthHubTotalRevenue.toFixed(2) }}
                </h3>
              </div>

              <div class="go-corner"></div>
            </a>

            <label>QR Code Total Revenue</label>
            <a class="datcard2 my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{ revenue.item2.qrCodeTotalRevenue.toFixed(2) }}</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>
          <div v-else class="col-md-4">
            <label>Health Hub Total Revenue</label>
            <a class="datcard2 my-3 space-y-2" href="#">
              <div>
                <h3 style="color:white; font-weight:bold;">
                  {{ countryCurrency}} 0
                </h3>
              </div>

              <div class="go-corner"></div>
            </a>

            <label>QR Code Total Revenue</label>
            <a class="datcard2 my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}} 0</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>

          <div v-if="revenue.item2" class="col-md-4">
            <label>All Revenue</label>
            <a class="datcard my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  (revenue.item2.healthHubTotalRevenue +
                  revenue.item2.invoiceTotalRevenue +
                  revenue.item2.qrCodeTotalRevenue).toFixed(2)
                  }}</span>
              </div>
              <div>
                <p style="color:white;" class="h5">
                  Paid
                </p>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  (revenue.item2.invoiceTotalPaid +
                  revenue.item2.healthHubTotalRevenue +
                  revenue.item2.qrCodeTotalRevenue).toFixed(2)
                  }}</span>
              </div>
              <div>
                <p style="color:white;" class="h5">
                  Balance
                </p>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  (revenue.item2.invoiceTotalRevenue -
                  revenue.item2.invoiceTotalPaid).toFixed(2)
                  }}</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>
          <div v-else class="col-md-4">
            <label>All Revenue</label>
            <a class="datcard my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}} 0</span>
              </div>
              <div>
                <p style="color:white;" class="h5">
                  Paid
                </p>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}} 0</span>
              </div>
              <div>
                <p style="color:white;" class="h5">
                  Balance
                </p>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}} 0</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>

          <div class="container my-5">
            <div class=" row ">
              <div class="col-md-4">
                <label for="startDate">Revenue Start Date</label>
                <input id="startDate" v-model="startDate" name="startDate" type="date" class="form-control" />
              </div>
              <div class="col-md-4">
                <label for="endDate">Revenue End Date</label>
                <input id="endDate" name="endDate" v-model="endDate" type="date" class="form-control" />
              </div>
              <div class="col-md-4">
                <label class="opacity-0" for="endDate">End Date</label>
                <button @click.prevent="getRevenue" class="btn btn-primary btn-block">
                  Filter
                </button>
              </div>
            </div>
          </div>

          <div v-if="revenue.item1 || revenue.item1 == null" class="col-md-4">
            <label>Invoice Revenue By Dates</label>
            <a class="datcard my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item1 ? revenue.item1.invoiceTotalRevenue.toFixed(2) : 0
                  }}</span>
              </div>
              <div>
                <p style="color:white;" class="h5">
                  Paid
                </p>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{ revenue.item1 ? revenue.item1.invoiceTotalPaid.toFixed(2) : 0 }}</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>

          <div v-if="revenue.item1 || revenue.item1 == null" class="col-md-4">
            <label>Health Hub Revenue By Dates</label>
            <a class="datcard2 my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item1 ? revenue.item1.healthHubTotalRevenue.toFixed(2) : 0
                  }}</span>
              </div>

              <div class="go-corner"></div>
            </a>

            <label>QR Codes Revenue By Dates</label>
            <a class="datcard2 my-3 align-item-center" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item1 ? revenue.item1.qrCodeTotalRevenue.toFixed(2) : 0
                  }}</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>

          <div v-if="revenue.item1 || revenue.item1 == null" class="col-md-4">
            <label>All Revenue By Dates</label>
            <a class="datcard my-3 space-y-2" href="#">
              <div>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item1 != null
                  ? (revenue.item1.healthHubTotalRevenue +
                  revenue.item1.invoiceTotalRevenue +
                  revenue.item1.qrCodeTotalRevenue).toFixed(2)
                  : 0
                  }}</span>
              </div>
              <div>
                <p style="color:white;" class="h4">
                  Paid
                </p>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item1 != null
                  ? (revenue.item1.healthHubTotalRevenue +
                  revenue.item1.invoiceTotalPaid +
                  revenue.item1.qrCodeTotalRevenue).toFixed(2)
                  : 0
                  }}</span>
              </div>
              <div>
                <p style="color:white;" class="h5">
                  Balance
                </p>
                <span style="color:white; font-weight:bold;" class="h3">
                  {{ countryCurrency}}
                  {{
                  revenue.item1 != null
                  ? (revenue.item1.invoiceTotalRevenue -
                  revenue.item1.invoiceTotalPaid).toFixed(2)
                  : 0
                  }}</span>
              </div>

              <div class="go-corner"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import { mapGetters } from "vuex";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";

export default {
  components: {
    Layout,
    FadeLoader
  },
  data() {
    return {
      loading: false,
      isloading: false,
      color: "#0b4369",
      title: "Dashboard",
      countryCurrency: JSON.parse(localStorage.getItem('userinfo')).data.countryCurrency,
      items: [
        {
          text: "Oonpay"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      businessType: window.localStorage.getItem("businessTypeId"),
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      revenue: [],
      transaction: "",
      transtartDate: new Date().toISOString().slice(0, 10),
      transendDate: new Date().toISOString().slice(0, 10),

      foodhub: {},
      totalPurchasedQuantity: "",
      totalRevenue: "",
      totalRevenueCount: "",

      foodHubTotalPurchasedQuantity: "",
      foodHubTotalRevenue: "",
      foodHubTotalRevenueCount: ""
    };
  },

  computed: {
    ...mapGetters("dashboard", ["transaction_type", "transactions"]),

    totalTransactions: function() {
      let total = 0;

      return this.transactions.reduce(
        (sum, transaction) => sum + transaction.amount,
        0
      );
    }
  },

  methods: {
    getRevenue() {
      const data = {
        startDate: this.startDate,
        endDate: this.endDate
      };

      this.loading = true;
      this.$store.dispatch("dashboard/getRevenue", data).then((response) => {
        this.revenue = response.data;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: err.response.data
        });
      });
    },

    GetTransactions() {
      this.isloading = true
      const payload = {
        startDate: this.transtartDate,
        endDate: this.transendDate,
        dashboardItemType: this.transaction
      };

      // console.log(payload)

      this.$store.dispatch("dashboard/getTransactions", payload)
        .catch((err) => {
          this.isloading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        })
      .finally(()=>{
        this.isloading = false
      })
    },

    getFoodHubMerchantRevenue() {
      const payload = {
        startDate: this.startDate,
        endDate: this.endDate
      };
      this.$store.dispatch("dashboard/getRevenue", payload).then((res) => {
        this.foodhub = res.data;
        this.totalPurchasedQuantity = res.data.item2?.totalPurchasedQuantity;
        this.totalRevenue = res.data.item2?.totalRevenue;
        this.totalRevenueCount = res.data.item2?.totalRevenue;

        this.foodHubTotalPurchasedQuantity =
          res.data.item2?.foodHubTotalPurchasedQuantity;
        this.foodHubTotalRevenue = res.data.item2?.foodHubTotalRevenue;
        this.foodHubTotalRevenueCount =
          res.data.item2?.foodHubTotalRevenueCount;
      }).catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }

    // GetTransactions() {
    //   const payload = {
    //     startDate: this.transtartDate,
    //     endDate: this.transendDate,
    //   };

    //   this.$store.dispatch("dashboard/getTransactions", payload)
    //   .then(res=>{
    //     this.transactions = res.data
    //     // console.log(this.transactions)
    //   })
    // },
  },
  created() {
    this.getRevenue();
    this.getFoodHubMerchantRevenue();
    this.$store.dispatch("dashboard/getTransactionType", this.businessType);
  }
};
</script>

<style scoped>
.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: rgb(224, 102, 36);
  border-radius: 0 4px 0 32px;
}

span {
  color: white;
}
.datcard {
  height: 14em;
  color: white;
  display: block;
  font-family: sans-serif;
  position: relative;
  background-color: rgb(3, 36, 77);
  border-radius: 4px;
  padding: 1em;
  z-index: 0;
  overflow: hidden;
  text-decoration: none !important;
}

.datcard2 {
  height: 5em;
  color: white;
  display: block;
  font-family: sans-serif;
  position: relative;
  background-color: rgb(3, 36, 77);
  border-radius: 4px;
  padding: 1em;
  z-index: 0;
  overflow: hidden;
  text-decoration: none !important;
}

.btn-info {
  background: #f15825 !important;
}

.datcard:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: rgb(224, 102, 36);
  height: 1em;
  width: 1em;
  border-radius: 100%;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.datcard:hover:before {
  transform: scale(45);
}

.datcard:hover p {
  transition: all 0.3s ease-out;
  color: rgb(255, 255, 255);
  color: rgba(255, 255, 255, 0.8);
}

.cardImg {
  background-color: #03244d;
  color: #03244d;
}

.cardIcon {
  color: #03244d;
}

.background-color {
  color: rgba(3, 36, 77, 0.1);
}

.invisible {
  display: none;
}

.visible {
  display: block;
}
.grid {
  background-color: #ffffff;
  border-radius: 10px 10px / 0px 0px;
  margin-bottom: 20px;
}

#pageHeader {
  margin-top: 25px;
  margin-bottom: 25px;
}
.gridHeader {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  margin-bottom: 10px;
}

.btn-primary,
.bg-primary {
  background: rgb(3, 36, 77) !important;
  color: #fff;
}
div.ex1 {
  height: 60px;
  overflow-y: scroll;
}

.fa-eye {
  color: #f15825;
}
</style>
